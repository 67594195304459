<template>
  <b-card-code>
    <b-card-body class="text-center">
        <h2 class="text-primary">
            Edit Dimension
        </h2>
        <b-card-text class="mb-2">
            <span>Quisque lacinia mauris a consectetur malesuada.<br>
                Integer ultrices dolor non tempus facilisis. </span>
        </b-card-text>
    </b-card-body>
    <b-form>
        <b-row class="mb-2 d-flex">
            <b-col
                cols="12"
                offset="3"
                md="9"
            >
                <b-button
                    variant="outline"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 btn-download"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="$router.push({ name: 'software-upload-complete'})"
                >
                    <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    Download empty template
                </b-button>
                <b-button
                    variant="outline"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1 btn-download"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="$router.push({ name: 'software-upload-complete'})"
                >
                    <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    Download data file
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="5"
            >
            <b-form-group
                label="Change parent"
            >
                <v-select
                    id="parent"
                    v-model="selectedParent"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="option => ! option.value.includes('select_parent')"
                    :options="ParentName"
                    label="text"
                />
            </b-form-group>
            </b-col>

            <b-col
            cols="12"
            md="5"
            >
            <b-form-group
                label="Other option"
                >
                <v-select
                    id="other-option"
                    v-model="selectedOtherOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :selectable="option => ! option.value.includes('select_otherOption')"
                    :options="otherOptionName"
                    label="text"
                />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="10"
            >
                <label for="textarea-default">Other option</label>
                <b-form-group>
                  <b-form-input
                      placeholder="Insert Value"
                  />
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col
            cols="12"
            offset="1"
            md="10"
            >
                <b-form-file
                v-model="file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                />
            </b-col>
        </b-row>

        <b-row style="padding-top: 1.5rem;">
            <b-col 
                cols="12"
                offset="1"
                md="10"
            >
                <b-button
                    variant="dark"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="$router.push({ name: 'software-upload-complete'})"
                >
                    Continue
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                </b-button>
            </b-col>
        </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BButton, BFormFile, BFormTextarea, BFormRadio, BRow, BCol, BFormGroup, BFormInput, BForm, BCardBody, BCardText, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    BButton,
    BFormFile,
    BFormTextarea,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCardBody,
    BCardText,
    BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      selectedParent: 'Select Parent',
      selectedOtherOption: 'Selection Options',
      parentName: [
        
      ],
      otherOptionName: [
        
      ],
    }
  },
}
</script>
