<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <dimension-upload />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import dimensionUpload from './dimensionUpload.vue'

export default {
  components: {
    BRow,
    BCol,
    dimensionUpload,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>